<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add Debit Note"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="col-md-4">
          <input
              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
              tabindex="1"
              type="date"
              placeholder=""
              v-model="formData.date"
          >
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Select Business"
              v-model="formData.business_id"
              :options="business"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Select Contact"
              v-model="formData.contact_profile_id"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Account Head"
              v-model="formData.account_head_id"
              :options="accountPayable"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Select Warehouse Location"
              v-model="formData.location_id"
              :options="locations"
              label="text"
              :reduce="text => text.id"
          />
        </div>

        <div class="col-md-4">
          <div class="d-flex align-items-center justify-content-md-end mb-1">
            <div class="input-group input-group-merge invoice-edit-input-group">
              <div class="input-group-text">
                <span>{{ prefix }}-</span>
              </div>
              <input type="number" min="1" class="form-control invoice-edit-input" placeholder="" v-model="serial">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-2">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
        <li class="nav-item">
          <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link"
             data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
        </li>
      </ul>
    </div>

    <div class="px-3">
      <Bill
          class="mt-2"
          v-for="(data, index) in accountsDetails"
          :key="index"
          :index="index"
          :data="data"
          :products="products"
          :accountHeads="accountHeads"
          :vatRate="vatRate"
          :isItem="isItem"
          :locationId="formData.location_id"
          @onClose="removeBill"
      />
      <Bill
          class="mt-2"
          v-for="(data, index) in itemsDetails"
          :key="index"
          :index="index"
          :data="data"
          :products="products"
          :accountHeads="accountHeads"
          :vatRate="vatRate"
          :isItem="isItem"
          :locationId="formData.location_id"
          @onClose="removeBill"
      />
    </div>

    <div class="px-2 mt-2">
      <button @click="addNewBill" class="btn btn-primary">Add line</button>
    </div>

    <div class="px-2">
      <div class="row justify-content-end">
        <div class="col-12 col-sm-3">
          <div class="row">
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">Sub total</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="subTotal"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">VAT</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="totalVAT"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">Total</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="total"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 px-2">
      <div class="mb-1">
        <label class="form-label" for="description">Memo</label>
        <vField
            as="textarea"
            name="description"
            type="number"
            class="form-control"
        />
      </div>
    </div>

    <div class="pb-5 px-2 mt-4">
      <div class="d-flex flex-wrap gap-1 gy-2">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>

<script setup>
import {ref, inject, onMounted, computed, watch} from 'vue'
import {vatRate} from '@/data/inventory.js'
import {useRouter, useRoute} from 'vue-router'
import handlePurchaseAndSalesReturn from '@/services/modules/purchaseSalesReturn'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import Loader from '@/components/atom/LoaderComponent'
import TitleButton from '@/components/atom/TitleButton'
import Bill from '@/components/molecule/company/inventory/purchase-return/Bill.vue'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handlePurchase from "@/services/modules/purchase";

const router = useRouter()
const route = useRoute()
let prefix = ref('')
let serial = ref('')

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {storePurchasesBill} = handlePurchaseAndSalesReturn()
const {fetchProductList, fetchAccountHead, fetchAccountPayable} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()

const companyId = computed(() => {
  return route.params.companyId
})
let loader = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])

onMounted(async () => {
  loader.value = true
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=debit_note_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date = new Date().toISOString().split('T')[0]

  const companyQuery = `?company_id=${companyId.value}`
  const productRes = fetchProductList(companyQuery)
  const accountHeadRes = fetchAccountHead(companyQuery)
  const businessRes = fetchBusinessList(companyQuery)
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)
  const contactRes = fetchContactProfiles(companyQuery)
  const payableRes = fetchAccountPayable(companyQuery)
  Promise.all([
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    businessRes.then(res => {
      if (res.data) business.value = res.data
    }),
    businessLocationRes.then(res => {
      if (res.data) locations.value = res.data
    }),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
    payableRes.then(res => {
      if (res.data) accountPayable.value = res.data
      if (res.data?.length) {
        formData.value.account_head_id = res.data[0].id
      }
    })
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  })
})
let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  business_id: null,
  location_id: null,
  status: 'active',
  bill_number: '',
  date: '',
  has_item_detail: true,
  description: '',
  account_details: [],
  item_details: [
    {
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      vat_amount: 0,
      description: ""
    }
  ],
})
const hasItemDetails = computed(() => {
  return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
  return formData.value.account_details
})
const accountsDetails = computed(() => {
  return formData.value.item_details
})

const isItem = computed(() => {
  return formData.value.has_item_detail ? true : false
})
const selectionText = computed(() => {
  return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

const subTotal = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseInt(item.amount)
      }
    })
  }

  return subTotal
})

const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }

  return vat.toFixed(2)
})

const total = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

watch(hasItemDetails, (newValue, oldValue) => {
  if (!newValue) {
    delete formData.value.item_details
    formData.value.account_details = [{
      account_head_id: null,
      amount: '',
      vat: 15,
      vat_amount: 0,
      description: '',
      taxable_amount: null,
      tax_rate: null
    }]
  }
  if (newValue) {
    delete formData.value.account_details
    formData.value.item_details = [{
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      vat_amount: 0,
      description: ""
    }]
  }
})

function navigateToListPage() {
  router.push({name: 'purchase-return-list', params: route.params, query: route.query})
}

function removeBill(index) {
  if (hasItemDetails.value) {
    formData.value.item_details.splice(index, 1)
  }
  if (!hasItemDetails.value) {
    formData.value.account_details.splice(index, 1)
  }
}

function addNewBill() {
  if (hasItemDetails.value) {
    formData.value.item_details.push({
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      vat_amount: 0,
      description: ''
    })
  }
  if (!hasItemDetails.value) {
    formData.value.account_details.push({
      account_head_id: null,
      amount: '',
      vat: 15,
      vat_amount: 0,
      description: '',
      taxable_amount: null,
      tax_rate: null
    })
  }
}

function handleSubmit(redirect = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }
  formData.value.bill_number = prefix.value + '-' + serial.value
  if (!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
  let copyFormData = JSON.parse(JSON.stringify(formData.value))
  copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
  if (isItem.value) {
    delete copyFormData.account_details
    copyFormData.item_details = formData.value.item_details
  } else {
    delete copyFormData.item_details
    copyFormData.account_details = formData.value.account_details
  }
  storePurchasesBill(copyFormData)
      .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
          resetForm()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
      })
}

// have to refactor
function resetForm() {
  if (!isItem.value) {
    formData.value.account_details = [{
      account_head_id: null,
      amount: '',
      vat: 15,
      vat_amount: 0,
      description: '',
      taxable_amount: null,
      tax_rate: null
    }]
  }
  if (isItem.value) {
    formData.value.item_details = [{
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      vat_amount: 0,
      description: ""
    }]
  }
}

</script>
