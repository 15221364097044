export const vatRate = [
    {
        label: '15%',
        value: 15,
    },
    {
        label: '5%',
        value: 5,
    },
    {
        label: '7.5%',
        value: 7.5,
    },
    {
        label: '10%',
        value: 10,
    },
    {
        label: '2%',
        value: 2,
    },
    {
        label: '0%',
        value: 0,
    },
]