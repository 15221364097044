import handleVoucherNo from "@/services/modules/refNumber";

export const JOURNAL_VOUCHER = 'journal_voucher';
export const RECEIPT_VOUCHER = 'receipt_voucher';
export const PAYMENT_VOUCHER = 'payment_voucher';
export const CONTRA_VOUCHER = 'contra_voucher';
const { fetchVoucherNo, fetchTXNNo} = handleVoucherNo()

function formatVoucher(str) {
	let voucherArr = str.split('-');
	let serial = voucherArr[voucherArr.length - 1];
	voucherArr.pop()
	let prefix = voucherArr.join('-');
	return {
		prefix,
		serial
	}
}

export async function generateTxnNumber(voucherQuery) {
	let res = await fetchTXNNo(voucherQuery);
	const {prefix, serial} = formatVoucher(res.data)
	
	return {
		 'prefix' : prefix,
		 'serial' : serial,
	 }
}

export function generateQuery(companyId, voucher_type, txn_type) {
	return `?company_id=${companyId}&voucher_type=${voucher_type}&txn_type=${txn_type}`
}

export async function generateVoucherNumber(voucherQuery) {
	let res = await fetchVoucherNo(voucherQuery);
	const {prefix, serial} = formatVoucher(res.data)
	
	return {
		 'prefix' : prefix,
		 'serial' : serial,
	 }
}
export function editVoucherNumber(voucherNo) {
	const {prefix, serial} = formatVoucher(voucherNo)
	
	return {
		 'prefix' : prefix,
		 'serial' : serial,
	 }
}