import Network from '@/services/network'
import {useQuery} from '../utils/query'

export default function handlePurchaseAndSalesReturn() {
    const network = new Network;
    const {allParams, getQueryString} = useQuery(20)

    const storePurchasesBill = (data) => {
        return network.api('post', '/inventory/purchases/return', data);
    }
    const fetchPurchaseReturnList = (query) => {
        return network.api('get', `/inventory/purchases/return${getQueryString()}`);
    }
    const fetchPurchaseReturnBill = (purchaseReturnId, query = '') => {
        return network.api('get', `/inventory/purchases/return/${purchaseReturnId}`+query);
    }

    const updatePurchaseReturnBill = (purchaseReturnId, data) => {
        return network.api('put', `/inventory/purchases/return/${purchaseReturnId}`, data);
    }
    const fetchSalesReturnList = (query) => {
        return network.api('get', `/inventory/sales/return${getQueryString()}`);
    }
    const storeSalesReturnBill = (data) => {
        return network.api('post', '/inventory/sales/return', data);
    }
    const fetchSalesReturn = (id, query) => {
        return network.api('get', `/inventory/sales/return/${id}${query}`);
    }
    return {
        storePurchasesBill,
        fetchPurchaseReturnList,
        allParams,
        fetchPurchaseReturnBill,
        updatePurchaseReturnBill,
        fetchSalesReturnList,
        storeSalesReturnBill,
        fetchSalesReturn
    }
}
