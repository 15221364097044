import Network from '@/services/network'

export default function handleVoucherNo() {
	const network = new Network;

	const fetchVoucherNo = (query) => {
		return network.api('get', '/get-voucher-number'+query);
	}
	const fetchTXNNo = (query) => {
		return network.api('get', '/get-transaction-number'+query);
	}

	const fetchServiceSerialNo = (query) => {
		return network.api('get', '/get-service-serial-no'+query);
	}

	const fetchAdmissionNo = (query) => {
		return network.api('get', '/hospital/ipd-registers/get-admission-no'+query);
	}

	return {
		fetchVoucherNo,
		fetchTXNNo,
		fetchServiceSerialNo,
		fetchAdmissionNo
	}
}
