import Network from '@/services/network'
import {ref} from 'vue'


export default function handleBusinessesLocations(){
    const network = new Network;
    const loading = ref(false)

    const fetchBusinessLocationsList = (query) => {
        return network.api('get', `/inventory/business-locations/all` + query);
    }

    const fetchProductsFromBusinessLocation = (query) => {
        return network.api('get', `/inventory/business-locations/products${query}`);
    }

    return {
        fetchBusinessLocationsList,
        fetchProductsFromBusinessLocation,
        loading
    }
}
